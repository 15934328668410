<template>
  <card-modal :visible="visible"
    :title="'Make/Model'"
    @ok="ok"
    @close="close"
    @cancel="cancel"
    :min-width="'80vw'">
    <div class="content">
      <div class="columns">
        <div class="column is-3">
          <div class="field">
            <p class="control is-expanded">
              <input class="input"
                type="text"
                v-model="filter.make"
                placeholder="Make"
                @keyup.enter="doSearch">
            </p>
          </div>
          <div class="field">
            <p class="control is-expanded">
              <input class="input"
                type="text"
                v-model="filter.model"
                placeholder="Model"
                @keyup.enter="doSearch">
            </p>
          </div>
          <div class="field">
            <p class="control is-expanded">
              <input class="input"
                type="text"
                v-model="filter.schedule"
                placeholder="Schedule"
                @keyup.enter="doSearch">
            </p>
          </div>
          <div class="field">
            <p class="control is-expanded">
              <input class="input"
                type="text"
                v-model="filter.mtaSchedule"
                placeholder="MTA Schedule"
                @keyup.enter="doSearch">
            </p>
          </div>
          <div class="field">
            <p class="control is-expanded">
              <input class="input"
                type="text"
                v-model="filter.bodies"
                placeholder="Bodies"
                @keyup.enter="doSearch">
            </p>
          </div>
          <div class="field has-addons">
            <div class="control">
              <!-- <the-mask class="input" placeholder="Month" v-model="filter.startMonth" :mask="['##']" @keyup.native.enter="doSearch" min="1" max="12" /> -->
              <div class="select">
                <select class="select"
                  v-model="filter.startMonth">
                  <option value="" />
                  <option v-for="m in months"
                    :value="`${m}`"
                    :key="m">{{ m | pad(2) }}</option>
                </select>
              </div>
            </div>
            <p class="control">
              <the-mask class="input"
                placeholder="Year"
                v-model="filter.startYear"
                :mask="['####']"
                @keyup.native.enter="doSearch" />
            </p>
          </div>
          <div class="field">
            <p class="control is-expanded">
              <input class="input"
                type="tel"
                v-model="filter.cylinders"
                placeholder="Cylinders"
                v-mask="'##'"
                @keyup.enter="doSearch">
            </p>
          </div>
          <div class="field">
            <div class="control is-expanded">
              <div class="pretty p-icon p-curve p-smooth p-bigger">
                <input type="checkbox"
                  v-model="filter.isNtar">
                <div class="state p-primary">
                  <i class="icon mdi mdi-check" />
                  <label>Show NTAR/LTAR Only</label>
                </div>
              </div>
            </div>
          </div>
          <div class="buttons is-pulled-right">
            <div class="control">
              <button class="button is-primary"
                @click="doSearch">
                <span class="icon is-small">
                  <i class="mdi mdi-magnify mdi-18px" />
                </span>
                <span>Filter</span>
              </button>
              <button class="button"
                @click="resetSearch">
                <span class="icon is-small">
                  <i class="mdi mdi-refresh mdi-18px" />
                </span>
                <span>Reset</span>
              </button>
            </div>
          </div>
        </div>
        <div class="column">
          <table class="table is-bordered is-striped is-narrow is-fullwidth table is-hoverable"
            ref="table">
            <thead>
              <tr>
                <!-- <th>No</th> -->
                <th>Make</th>
                <th>Model</th>
                <th>Bodies</th>
                <th>Cyl</th>
                <th>Built Start</th>
                <th>Built End</th>
                <th>Schedule</th>
                <th>MTA Schedule</th>
              </tr>
            </thead>
            <tfoot>
              <tr>
                <th colspan="7">
                  <pagination class=""
                    :current-page="filter.pageIndex"
                    :total-rows="totalRows"
                    :page-size="filter.pageSize"
                    @pageChanged="onPageChange" />
                </th>
              </tr>
            </tfoot>
            <tbody>
              <tr v-for="(c, idx) in data"
                :key="idx"
                @click="select(c)">
                <!-- <td>{{(filter.pageIndex -1) * filter.pageSize + idx + 1}}</td> -->
                <td>
                  {{ c.make }}
                </td>
                <td>
                  {{ c.model }}
                </td>
                <td>
                  {{ c.bodies }}
                </td>
                <td>
                  {{ c.cylinders }}
                </td>
                <td>
                  {{ c.startMonth | pad(2) }}{{ c.startMonth ? '/' : '' }}{{ c.startYear }}
                </td>
                <td>
                  {{ c.endMonth | pad(2) }}{{ c.endMonth ? '/' : '' }}{{ c.endYear }}
                </td>
                <td>
                  {{ c.schedule }}
                </td>
                <td>
                  {{ c.mtaSchedule }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </card-modal>
</template>

<script>
import { TheMask, mask } from 'vue-the-mask'
import { CardModal } from '@/components/BulmaModal'
import Pagination from '@/components/BulmaPagination'
import CarModelService from '@/components/carmodel/CarModelService'
import { NumberFiltersMixin } from '@/components/mixins/filters'

export default {
  name: 'CarModelSearchModal',
  directives: { mask },
  components: {
    TheMask,
    CardModal,
    Pagination
  },
  mixins: [NumberFiltersMixin],
  props: {
    visible: Boolean,
    isNtar: {
      type: Boolean,
      default: false
    },
    make: {
      type: String,
      default: ''
    },
    model: {
      type: String,
      default: ''
    },
    series: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      filter: {
        make: '',
        model: '',
        schedule: '',
        mtaSchedule: '',
        bodies: '',
        startMonth: '',
        startYear: '',
        cylinders: '',
        isNtar: this.isNtar,
        pageIndex: 1,
        pageSize: 13,
        sortOrder: 'asc',
        sortColumn: 'make'
      },
      totalRows: 0,
      data: [],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    }
  },
  watch: {
    'filter.name': function (value) {
      console.log('lets search for ' + value)
    },
    visible(newVal, oldVal) {
      if (newVal && (this.make || this.model)) {
          this.filter.make = this.make
          this.filter.model = this.model
          this.getListing()
      }
    }
  },
  mounted() {
    this.getListing()
  },
  methods: {
    onPageChange(ev) {
      this.filter.pageIndex = ev
      this.getListing()
    },
    doSearch() {
      this.filter.pageIndex = 1
      this.filter.sortOrder = 'asc'
      this.filter.sortColumn = 'make'
      if (this.filter.startYear && this.filter.startYear.length === 2) {
        const date = new Date(`01/01/${this.filter.startYear}`)
        this.filter.startYear = date.getFullYear().toString()
      } else if (this.filter.startYear.length !== 4) {
        this.filter.startYear = '' // invalid year reset
      }
      this.getListing()
    },
    resetSearch() {
      this.filter.make = ''
      this.filter.model = ''
      this.filter.schedule = ''
      this.filter.mtaSchedule = ''
      this.filter.bodies = ''
      this.filter.startMonth = ''
      this.filter.startYear = ''
      this.filter.cylinders = ''
      this.filter.isNtar = this.isNtar
      this.filter.pageIndex = 1
      this.filter.pageSize = 13
      this.filter.sortOrder = 'asc'
      this.filter.sortColumn = 'make'

      this.getListing()
    },
    async getListing() {
      console.log()
      const rs = await CarModelService.getCarModels(this.filter)

      this.totalRows = rs.data.totalRows
      this.data = rs.data.result

      return false
    },
    async select(model) {
      const carModelReq = await CarModelService.getCarModel(model.id)
      const carModel = carModelReq.data
      carModel.isNew = false

      this.$emit('ok', carModel)
      // this.visible = false
    },
    close() {
      // If this is not here
      // Dialog will not show up if [x] or outside of modal is clicked
      // this.visible = false
      this.$emit('cancel')
    },
    async ok() {
      // const carModelReq = await CarModelService.getCarModel(carReq.data.id)
      // const carModel = carModelReq.data
      // carModel.isNew = false
      //
      // this.$emit('ok', carModel)
      // this.visible = false
      this.$emit('ok')
    },
    cancel() {
      // this.visible = false
      this.$emit('cancel')
    }
  }
}
</script>
<style type="scss">
.pagination ul {
  padding: 0;
  margin: 0;
  list-style-type: none !important;
}
</style>
